@font-face {
  font-family: "EurostileExtended"; /*Can be any text*/
  src: local("EurostileExtended"),
    url("./shared/fonts/Eurostile\ Extended-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "EurostileExtendedBlack"; /*Can be any text*/
  src: local("EurostileExtendedBlack"),
    url("./shared/fonts/Eurostile\ Extended-Black.ttf") format("truetype");
}
